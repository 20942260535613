<template>
	<form @submit.prevent="handleSubmit(!v$.$invalid)" class="p-grid">
		<Toast />
		<ConfirmDialog></ConfirmDialog>
		
		<div class="grid p-fluid stickBar">
			<div class="col-12">
				<div class="card card-w-title" id="stickBar">
					<Menubar :model="nestedMenuitems">
						<template #end>
							<div class="flex justify-content-end flex-wrap card-container green-container">
								<div
									class="flex align-items-center justify-content-center font-bold text-gray-500 text-xl m-2">
									{{ mainData.ad }}
								</div>
								<div class="flex align-items-center justify-content-center m-2">
									<Button class="p-button-rounded p-button-danger mr-0 mb-0"
										v-if="$router.options.history.state.back != null" icon="pi pi-times"
										@click="$router.go(-1)" />
								</div>
							</div>
						</template>
					</Menubar>
				</div>
			</div>
		</div>

		<div class="grid">
			
		</div>

		<div class="grid">
				<div class="col-12">
					<div class="card">

						<TabView v-model:activeIndex="tabviewActiveIndex" :scrollable="true">

							<!-- ÜRÜN -->
							<TabPanel>
								<template #header>
									<div class="blue-bgcolor solid-surface-text-color p-3">
										<div class="flex align-items-center">
											<i class="pi pi-bars"></i>
											<h6 class="m-2" :class="{'pl-2': !isRTL, 'pr-2': isRTL }">Genel</h6>
										</div>
										<div class="flex justify-content-between mt-1 flex-wrap">
											<div class="flex flex-column" style="width: 150px;">
												<span class="mb-1 fs-xlarge">ÜRÜN</span>
											</div>
										</div>
									</div>
								</template>
								<div class="col-12">
									<div class="card">
										<div class="grid">
											<div class="col-9">
												<div class="field p-fluid">
													<label for="ad">Ad</label>
													<InputText id="ad" type="text" v-model="mainData.ad" :disabled="isDisabled" />
												</div>
											</div>
											<div class="col-3">
												<div class="field p-fluid">
													<label for="urunKodu">Ürün Kodu</label>
													<InputText id="urunKodu" type="text" v-model="mainData.urunKodu" :disabled="isDisabled" />
												</div>
											</div>
											<div class="col-12">
												<div class="field p-fluid">
													<label for="urunModelAdiEn">Ürün Model Adı (En)</label>
													<InputText id="urunModelAdiEn" type="text" v-model="mainData.urunModelAdiEn" :disabled="isDisabled" />
												</div>
											</div>
											<div class="col-6">
												<div class="field p-fluid">
													<label for="urunDetayTanimiTr">Ürün Detay Tanımı (Tr)</label>
													<Textarea id="urunDetayTanimiTr" rows="6" :autoResize="false" v-model="mainData.urunDetayTanimiTr" :disabled="isDisabled" />
												</div>
											</div>
											<div class="col-6">
												<div class="field p-fluid">
													<label for="urunDetayTanimiEn">Ürün Detay Tanımı (En)</label>
													<Textarea id="urunDetayTanimiEn" rows="6" :autoResize="false" v-model="mainData.urunDetayTanimiEn" :disabled="isDisabled" />
												</div>
											</div>
											<div class="col-3">
												<div class="field p-fluid">
													<label for="garantiSuresiAy">Garanti Süresi (Ay)</label>
													<InputNumber id="garantiSuresiAy" v-model="mainData.garantiSuresiAy" :disabled="isDisabled"/>
												</div>
											</div>
											<div class="col-3">
												<div class="field p-fluid">
													<label for="standartOpsiyonMu">Standart Opsiyon Mu?</label>
													<Dropdown id="standartOpsiyonMu" v-model="mainData.standartOpsiyonMu" :options="SM_standartOpsiyonMu" optionLabel="Value" optionValue="AttributeValue" placeholder="Standart Opsiyon Mu?" :showClear="true" :disabled="isDisabled" />
												</div>
											</div>
											<div class="col-3">
												<div class="field p-fluid">
													<label for="urunPdfAdi">Ürün PDF Adı</label>
													<InputText id="urunPdfAdi" type="text" v-model="mainData.urunPdfAdi" :disabled="isDisabled" />
												</div>
											</div>
											<div class="col-3">
												<div class="field p-fluid">
													<label for="hedefMarka">Hedef Marka</label>
													<Dropdown id="hedefMarka" v-model="mainData.hedefMarka" :options="SM_hedefMarka" optionLabel="Value" optionValue="AttributeValue" placeholder="Hedef Marka" :showClear="true" :disabled="isDisabled"/>
												</div>
											</div>
											<div class="col-3">
												<div class="field p-fluid">
													<label for="urunTuru">Ürün Türü</label>
													<Dropdown id="urunTuru" v-model="mainData.urunTuru" :options="SM_urunTuru" optionLabel="Value" optionValue="AttributeValue" placeholder="Ürün Türü" :showClear="true" :disabled="isDisabled" />
												</div>
											</div>
											<div class="col-3">
												<div class="field p-fluid">
													<label for="urunAilesi">Ürün Ailesi</label>
													<TreeSelect id="urunAilesi" v-model="selectedUrunAilesi" :options="urunAilesiNodes.jsonData" placeholder="Seçiniz" @node-select="UrunAilesiSelect($event)" :disabled="isDisabled"></TreeSelect>
												</div>
											</div>
										</div>
									</div>
								</div>

								<div class="col-12">
									<div class="card">
										<h5>VARSAYILAN DEĞERLER</h5>
										<div class="grid">
											<div class="col-4">
												<div class="field p-fluid">
													<EntityLookup id="parabirimi" v-model="mainData.paraBirimiName" ref="entity_parabirimi"
														label="Para Birimi" entityName="transactioncurrency" nameField="currencyname" :disabled="isDisabled"
														:state="true" @itemSelected="paraBirimiSelected = $event"
														@itemCleared="paraBirimiSelected = null"></EntityLookup>
												</div>
											</div>
											<div class="col-4">
												<div class="field p-fluid">
													<EntityLookup id="varsayilanFiyatListesi" v-model="mainData.varsayilanFiyatListesiName"
														ref="entity_varsayilanFiyatListesi" label="Varsayılan Fiyat Listesi" :disabled="isDisabled"
														entityName="pricelevel" nameField="name" :state="true"
														@itemSelected="varsayilanFiyatListesiSelected = $event"
														@itemCleared="varsayilanFiyatListesiSelected = null"></EntityLookup>
												</div>
											</div>
											<div class="col-4">
												<div class="field p-fluid">
													<label for="varsayilanFiyat">Varsayılan Fiyat</label>
													<InputNumber id="varsayilanFiyat" v-model="mainData.varsayilanFiyat" :disabled="isDisabled" />
												</div>
											</div>
										</div>
									</div>
								</div>

								<div class="col-12">
									<div class="card">
										<h5>TEZGAH BİLGİLERİ</h5>
										<div class="grid">
											<div class="col-12">
												<div class="field p-fluid">
													<label for="malTanim">Mal Tanımı</label>
													<InputText id="malTanim" type="text" v-model="mainData.malTanimi" :disabled="isDisabled" />
												</div>
											</div>
											<div class="col-4">
												<div class="field p-fluid">
													<label for="urunSeri">Ürün Seri</label>
													<InputText id="urunSeri" type="text" v-model="mainData.urunSeri" :disabled="isDisabled" />
												</div>
											</div>
											<div class="col-4">
												<div class="field p-fluid">
													<label for="origin">Origin</label>
													<Dropdown id="origin" v-model="mainData.origin" :options="SM_origin" optionLabel="Value" optionValue="AttributeValue" placeholder="Origin" :showClear="true" :disabled="isDisabled" />
												</div>
											</div>
											<div class="col-4">
												<div class="field p-fluid">
													<label for="yuklemeAraci">Yükleme Aracı</label>
													<Dropdown id="yuklemeAraci" v-model="mainData.yuklemeAraci" :options="SM_yuklemeAraci" optionLabel="Value" optionValue="AttributeValue" placeholder="Yükleme Aracı" :showClear="true" :disabled="isDisabled" />
												</div>
											</div>
										</div>
									</div>
								</div>

								<div class="col-12">
									<div class="card">
										<h5>İNDİRİMLER</h5>
										<div class="grid">
											<div class="col-4">
												<div class="field p-fluid">
													<label for="indirimA">İndirim A %</label>
													<InputNumber id="indirimA" v-model="mainData.indirimA" mode="decimal" :minFractionDigits="2" :disabled="isDisabled" />
												</div>
											</div>
											<div class="col-4">
												<div class="field p-fluid">
													<label for="indirimB">İndirim B %</label>
													<InputNumber id="indirimB" v-model="mainData.indirimB" mode="decimal" :minFractionDigits="2" :disabled="isDisabled" />
												</div>
											</div>
											<div class="col-4">
												<div class="field p-fluid">
													<label for="indirimC">İndirim C %</label>
													<InputNumber id="indirimC" v-model="mainData.indirimC" mode="decimal" :minFractionDigits="2" :disabled="isDisabled" />
												</div>
											</div>
										</div>
									</div>
								</div>

							</TabPanel>
							
							<!-- OPSİYONLAR -->
							<TabPanel>
								<template #header>
									<div class="purple-bgcolor solid-surface-text-color p-3">
										<div class="flex align-items-center">
											<i class="pi pi-bars"></i>
											<h6 class="m-2" :class="{'pl-2': !isRTL, 'pr-2': isRTL }">OPSİYONLAR</h6>
										</div>
										<div class="flex justify-content-between mt-1 flex-wrap">
											<div class="flex flex-column" style="width: 150px;">
												<span class="mb-1 fs-xlarge">{{ mainData.adet_opsiyon}} adet</span>
											</div>
										</div>
									</div>
								</template>

								<DataTable
									:value="opsiyonlarData"
									v-model:selection="opsiyonlarSelectedItem"
									:rowHover="true"
									class="p-datatable"
									responsiveLayout="scroll"
									:paginator="true"
									:rows="50"
									@row-dblclick="onRowSelectOpsiyon($event)"
									paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
									:rowsPerPageOptions="[10,50,250]"
									currentPageReportTemplate="{totalRecords} kaydın {first} ile {last} arası gösteriliyor">
									<template #empty>
										Kayıt Bulunamadı.
									</template>
									<template #loading>
										Veriler yükleniyor, lütfen bekleyiniz...
									</template>
									<Column selectionMode="multiple" style="flex: 0 0 3rem" headerStyle="width: 3rem"></Column>
									<Column field="urunKodu" header="Ürün kodu" headerStyle="width:100px" :sortable="true" ></Column>
									<Column field="urunModelAdiTr" header="Ürün Model Adı (Tr)" :sortable="true"></Column>
									<Column field="urunModelAdiEn" header="Ürün Model Adı (En)" :sortable="true"></Column>
									<Column field="varsayilanFiyat" header="Varsayılan Fiyat" headerStyle="width:90px" bodyStyle="text-align: right" :sortable="true">
										<template #body="slotProps">
											{{ formatNumber(slotProps.data.varsayilanFiyat) }}
										</template>
									</Column>
								</DataTable>
							</TabPanel>

							<!-- FİYAT LİSTESİ -->
							<TabPanel>
								<template #header>
									<div class="orange-bgcolor solid-surface-text-color p-3">
										<div class="flex align-items-center">
											<i class="pi pi-bars"></i>
											<h6 class="m-2" :class="{'pl-2': !isRTL, 'pr-2': isRTL }">FİYAT LİSTESİ</h6>
										</div>
										<div class="flex justify-content-between mt-1 flex-wrap">
											<div class="flex flex-column" style="width: 150px;">
												<span class="mb-1 fs-xlarge">{{ mainData.adet_productpricelevel }} adet</span>
											</div>
										</div>
									</div>
								</template>

								<CrmDataTable :key="CrmDataTable_productpricelevel_key" baseEntityName="productpricelevel" :options="CrmDataTable_productpricelevel_options"
								:isHeaderVisible="true" :doubleClickOpenEntity="false"  @onRowSelect="onRowSelectFiyatListesiKalemi"  />
							</TabPanel>

							<!-- TEKLİFLER -->
							<TabPanel v-if="isTekliflerYetki">
								<template #header>
									<div class="green-bgcolor solid-surface-text-color p-3">
										<div class="flex align-items-center">
											<i class="pi pi-bars"></i>
											<h6 class="m-2" :class="{'pl-2': !isRTL, 'pr-2': isRTL }">TEKLİFLER</h6>
										</div>
										<div class="flex justify-content-between mt-1 flex-wrap">
											<div class="flex flex-column" style="width: 150px;">
												<span class="mb-1 fs-xlarge">{{ mainData.adet_quote}} adet</span>
											</div>
										</div>
									</div>
								</template>

								<CrmDataTable :key="CrmDataTable_quote_key" baseEntityName="quote" :options="CrmDataTable_quote_options" :isHeaderVisible="true" :doubleClickOpenEntity="true" />
							</TabPanel>

							<!-- TEZGAHLAR -->
							<TabPanel v-if="isTezgahlarYetki">
								<template #header>
									<div class="cyan-bgcolor solid-surface-text-color p-3">
										<div class="flex align-items-center">
											<i class="pi pi-bars"></i>
											<h6 class="m-2" :class="{'pl-2': !isRTL, 'pr-2': isRTL }">TEZGAHLAR</h6>
										</div>
										<div class="flex justify-content-between mt-1 flex-wrap">
											<div class="flex flex-column" style="width: 150px;">
												<span class="mb-1 fs-xlarge">{{ mainData.adet_bm_unite }} adet</span>
											</div>
										</div>
									</div>
								</template>

								<CrmDataTable :key="CrmDataTable_bm_unite_key" baseEntityName="bm_unite" :options="CrmDataTable_bm_unite_options"
								:isHeaderVisible="true" :doubleClickOpenEntity="true" />
							</TabPanel>

							<!-- SERVİS RAPORLARI -->
							<TabPanel v-if="isServisRaporlariYetki">
								<template #header>
									<div class="pink-bgcolor solid-surface-text-color p-3">
										<div class="flex align-items-center">
											<i class="pi pi-bars"></i>
											<h6 class="m-2" :class="{'pl-2': !isRTL, 'pr-2': isRTL }">SERVİS RAPORLARI</h6>
										</div>
										<div class="flex justify-content-between mt-1 flex-wrap">
											<div class="flex flex-column" style="width: 150px;">
												<span class="mb-1 fs-xlarge">{{ mainData.adet_serviceappointment }} adet</span>
											</div>
										</div>
									</div>
								</template>

								<CrmDataTable :key="CrmDataTable_serviceappointment_key" baseEntityName="serviceappointment" :options="CrmDataTable_serviceappointment_options"
								:isHeaderVisible="true" :doubleClickOpenEntity="true" />
							</TabPanel>

							<!-- YEDEK PARÇA TEKLİF SİPARİŞ -->
							<TabPanel v-if="isYedekParcaTeklifSiparis">
								<template #header>
									<div class="green-bgcolor solid-surface-text-color p-3">
										<div class="flex align-items-center">
											<i class="pi pi-bars"></i>
											<h6 class="m-2" :class="{'pl-2': !isRTL, 'pr-2': isRTL }">Y.P. TEKLİF / SİPARİŞ</h6>
										</div>
										<div class="flex justify-content-between mt-1 flex-wrap">
											<div class="flex flex-column" style="width: 150px;">
												<span class="mb-1 fs-xlarge">{{ mainData.adet_yedekparcateklifsiparis}} adet</span>
											</div>
										</div>
									</div>
								</template>

								<CrmDataTable :key="CrmDataTable_bm_yedekparcateklifsiparis_key" baseEntityName="bm_yedekparcateklifsiparis" :options="CrmDataTable_bm_yedekparcateklifsiparis_options"
								:isHeaderVisible="true" :doubleClickOpenEntity="true" />
							</TabPanel>
						</TabView>
					</div>
				</div>
		</div>

		<Dialog v-model:visible="displayFiyatListesiKalemi" :breakpoints="{'960px': '90vw', '640px': '90vw'}" :style="{width: '50vw'}" header="Fiyat Listesi Kalemi" :modal="true" :closable="true" :showHeader="true" :draggable="true">
            <br>
            <div class="p-4">
                <div class="grid">
					<div class="col-12">
						<div class="field p-fluid">
							<h5>{{ dataFiyatListesiKalemi.name }}</h5>
						</div>
					</div>
                    <div class="col-12">
						<div class="field p-fluid">
							<label for="tutar">Tutar</label>
							<InputNumber id="tutar" v-model="dataFiyatListesiKalemi.amount" mode="decimal" :minFractionDigits="0" :disabled="isYetki" />
						</div>
					</div>
                </div>

                <Button v-if="!isYetki" label="Kaydet" class="mt-3 p-button-secondary" icon="pi pi-save" @click="OnSaveFiyatListesiKalemi" />
            </div>
            
        </Dialog>
	</form>
</template>

<script>
import CrmService from "../service/CrmService";
import { useVuelidate } from "@vuelidate/core";
import { email, helpers, required } from "@vuelidate/validators";
import user from '../store/user';

export default {
	setup: () => ({ v$: useVuelidate() }),
	data() {
		return {
			crmService: null,
			submitted: false,
			OBJECT_TYPE_CODE: 1024,
			keySubGrid: 0,
			mainData: {},
			opsiyonlarData: [],
			opsiyonlarSelectedItem: [],
			tabviewActiveIndex: 0,
			nestedMenuitems: [
				{
                    label: "Sil",
                    icon: "pi pi-fw pi-trash",
                    command: () => { this.OnDelete(); },
					visible: () => this.isDeleted
                },
			],
			selectedUrunAilesi: null, //{ "9e344a2f-2208-4fa3-b0e3-0f015bd25bc7": true },
			urunAilesiNodes: [],

			CrmDataTable_quote_key: 0,
			CrmDataTable_quote_options: {
				searchAttributes: ["quotenumber", "customeridname", "bm_uniteidname", "bm_urunidname", "bm_urunailesiidname", "name", "bm_satisyonteminame", "opportunityidname", "bm_po", "bm_musterifaturaidname", "bm_urunmarkaname", "bm_ilgilikisiidname", "customeridname"],
				filterParent: [
					{ 'name': 'bm_urunid', 'filterOperator': 'eq', 'value': this.entityId }
				],
				forceSavedQueryId: '00000000-0000-0000-00AA-000010013100',
				views: [
					{ 'viewName': 'Teklifler', 'savedQueryId': '00000000-0000-0000-00AA-000010013100' },
				],
				preventSystemViews: true,
				preventUserViews: true
            },

			CrmDataTable_bm_yedekparcateklifsiparis_key: 0,
			CrmDataTable_bm_yedekparcateklifsiparis_options: {
				searchAttributes: ["bm_teklifbasligi", "bm_teklifno", "bm_siparisno", "bm_musterigercekidname", "bm_urunidname", "bm_uniteidname"],
				filterParent: [
					{ 'name': 'bm_urunid', 'filterOperator': 'eq', 'value': this.entityId }
				],
				forceSavedQueryId: '85EC6CFB-AC75-E211-9AD9-E61F135C9177',
				views: [
					{ 'viewName': '5 - Tümü', 'savedQueryId': '85EC6CFB-AC75-E211-9AD9-E61F135C9177' },
				],
				preventSystemViews: true,
				preventUserViews: true
            },

			CrmDataTable_bm_unite_key: 0,
			CrmDataTable_bm_unite_options: {
				searchAttributes: ["bm_kurulummusteriidname", "bm_frmno", "bm_serino", "bm_po", "bm_urunidname", "bm_siparisnoidname", "bm_teklifnoidname", "bm_markaname", "bm_satisyapanfirmaname", "bm_turuname"],
				filterParent: [
					{ 'name': 'bm_urunid', 'filterOperator': 'eq', 'value': this.entityId }
				],
				forceSavedQueryId: '067671D1-4700-42C7-8B91-CE8EBAAFE78D',
				views: [
					{ 'viewName': 'Etkin Tezgah', 'savedQueryId': '067671D1-4700-42C7-8B91-CE8EBAAFE78D' },
				],
				preventSystemViews: true,
				preventUserViews: true
            },

			CrmDataTable_productpricelevel_key: 0,
			CrmDataTable_productpricelevel_options: {
				searchAttributes: ["productidname"],
				filterParent: [
					{ 'name': 'productid', 'filterOperator': 'eq', 'value': this.entityId }
				],
				forceSavedQueryId: '9EE21B43-10A0-4C1B-B8D4-8A7BBAD16B48',
				views: [
					{ 'viewName': 'Fiyat Listesi Kalemleri', 'savedQueryId': '9EE21B43-10A0-4C1B-B8D4-8A7BBAD16B48' },
				],
				preventSystemViews: true,
				preventUserViews: true
            },

			CrmDataTable_serviceappointment_key: 0,
			CrmDataTable_serviceappointment_options: {
				searchAttributes: ["bm_kod", "bm_servistalebiidname", "bm_uniteidname", "bm_firmaidname", "bm_urunidname", "subject"],
				filterParent: [
					{ 'name': 'bm_urunid', 'filterOperator': 'eq', 'value': this.entityId }
				],
				forceSavedQueryId: '36C60CBD-AFB1-4217-9211-8189DBF5C07C',
				views: [
					{ 'viewName': 'Tüm Servis Raporları', 'savedQueryId': '36C60CBD-AFB1-4217-9211-8189DBF5C07C' },
				],
				preventSystemViews: true,
				preventUserViews: true
            },

			displayFiyatListesiKalemi: false,
			dataFiyatListesiKalemi: {}
		}
	},
	async created() {
		this.crmService = new CrmService();

		console.log('created');
		window.addEventListener('scroll', this.handleScroll);

		let loader = this.$loading.show({
			container: this.$refs.smsContainer
		});

        debugger;
		if (this.entityId != null) {
			try {
				debugger;
				let data = await this.crmService.getUrunById(this.entityId);
				if (data.yetkiVarmi == false) {
					this.$router.replace({ name: 'accessdenied' });
				}
				
				if (data.jsonData.length > 0) {
					this.mainData = data.jsonData[0];
					this.opsiyonlarData = data.opsiyonlarData;

					const pageHistoryItem = { entityLetter: 'Ürn', entityName: 'product', ID: this.mainData.ProductId, title: this.mainData.urunKodu + '\r\n\r\n' + this.mainData.ad };
					this.$store.commit('SET_PAGEHISTORY', pageHistoryItem);

					if (this.mainData["paraBirimiId"]) {
						this.$refs.entity_parabirimi.setDefaultData({ "Value": this.mainData["paraBirimiId"], "Name": this.mainData["paraBirimiName"] });
					}

					if (this.mainData["varsayilanFiyatListesiId"]) {
						this.$refs.entity_varsayilanFiyatListesi.setDefaultData({ "Value": this.mainData["varsayilanFiyatListesiId"], "Name": this.mainData["varsayilanFiyatListesiName"] });
					}

					if (this.mainData["urunAilesiId"]) {
						this.selectedUrunAilesi = { [this.mainData["urunAilesiId"]] : true };
					}

					
					this.CrmDataTable_bm_unite_options.filterParent[0].value = this.entityId;
					this.CrmDataTable_bm_yedekparcateklifsiparis_options.filterParent[0].value = this.entityId;
					this.CrmDataTable_productpricelevel_options.filterParent[0].value = this.entityId;
					this.CrmDataTable_serviceappointment_options.filterParent[0].value = this.entityId;
					this.CrmDataTable_quote_options.filterParent[0].value = this.entityId;

					this.CrmDataTable_bm_unite_key++;
					this.CrmDataTable_bm_yedekparcateklifsiparis_key++;
					this.CrmDataTable_productpricelevel_key++;
					this.CrmDataTable_serviceappointment_key++;
					this.CrmDataTable_quote_key++;

					this.keySubGrid++;
				}

				this.urunAilesiNodes = await this.crmService.GetSubjects();
			} catch (error) {
				console.log(error);
				//this.$router.push('/');
			}
			finally {
				loader.hide();
			}
		}
		else {
			//this.$router.push('/');
			loader.hide();
		}
    },
    mounted() {

    },
	computed: {
		entityId() {
			return this.$route.params.id;
		},
		profileData(){
			return this.$store.getters.getProfile;
		},
		isDisabled: function() {
			return true;
		},
		isTekliflerYetki(){
			if (this.profileData) {
				const yetkisiVarmi = user.checkPermissionModul(this.profileData, 'Teklifler');
				if (yetkisiVarmi == true) {
					return true;
				}
				else {
					return false;
				}
			}
			else {
				return false;
			}
		},
		isTezgahlarYetki(){
			if (this.profileData) {
				const yetkisiVarmi = user.checkPermissionModul(this.profileData, 'Tezgahlar');
				if (yetkisiVarmi == true) {
					return true;
				}
				else {
					return false;
				}
			}
			else {
				return false;
			}
		},
		isServisRaporlariYetki(){
			if (this.profileData) {
				const yetkisiVarmi = user.checkPermissionModul(this.profileData, 'Servis Raporları');
				if (yetkisiVarmi == true) {
					return true;
				}
				else {
					return false;
				}
			}
			else {
				return false;
			}
		},
		isYedekParcaTeklifSiparis(){
			if (this.profileData) {
				const yetkisiVarmi = user.checkPermissionModul(this.profileData, 'Y.P. Teklif / Sipariş');
				if (yetkisiVarmi == true) {
					return true;
				}
				else {
					return false;
				}
			}
			else {
				return false;
			}
		},
		isYetki: function() {
			if (this.profileData) {
				if (this.profileData.moduller) {
					const filtered = this.profileData.moduller.filter(x => x.name == 'Ürünler');
					if (filtered.length > 0) {
						return filtered[0].isWrite == false;
					}
				}
			}

			return true;
		},
		isDeleted: function() {
			if (this.profileData) {
				if (this.profileData.moduller) {
					const filtered = this.profileData.moduller.filter(x => x.name == 'Ürünler');
					if (filtered.length > 0) {
						return filtered[0].isDelete == true;
					}
				}
			}

			return false;
		},
		SM_standartOpsiyonMu: function () {
			return this.$store.getters.getStringMapByEntityId(this.OBJECT_TYPE_CODE, 'bm_standartmi', 'bool');
		},
		SM_hedefMarka: function () {
			return this.$store.getters.getStringMapByEntityId(this.OBJECT_TYPE_CODE, 'bm_hedefmarka');
		},
		SM_urunTuru: function () {
			return this.$store.getters.getStringMapByEntityId(this.OBJECT_TYPE_CODE, 'producttypecode');
		},
		SM_origin: function () {
			return this.$store.getters.getStringMapByEntityId(this.OBJECT_TYPE_CODE, 'bm_origin');
		},
		SM_yuklemeAraci: function () {
			return this.$store.getters.getStringMapByEntityId(this.OBJECT_TYPE_CODE, 'bm_yuklemearac');
		},
		paraBirimiSelected: {
			get: function () {
				if (this.mainData["paraBirimiId"]) {
					return { "Value": this.mainData["paraBirimiId"], "Name": this.mainData["paraBirimiName"] }
				} else { return null; }
			},
			set: function (newValue) {
				if (newValue == null) {
					this.mainData["paraBirimiId"] = null;
					this.mainData["paraBirimiName"] = null;
				}
				else {
					this.mainData["paraBirimiId"] = newValue.Value;
					this.mainData["paraBirimiName"] = newValue.Name;
				}
			}
		},
		varsayilanFiyatListesiSelected: {
			get: function () {
				if (this.mainData["varsayilanFiyatListesiId"]) {
					return { "Value": this.mainData["varsayilanFiyatListesiId"], "Name": this.mainData["varsayilanFiyatListesiName"] }
				} else { return null; }
			},
			set: function (newValue) {
				if (newValue == null) {
					this.mainData["varsayilanFiyatListesiId"] = null;
					this.mainData["varsayilanFiyatListesiName"] = null;
				}
				else {
					this.mainData["varsayilanFiyatListesiId"] = newValue.Value;
					this.mainData["varsayilanFiyatListesiName"] = newValue.Name;
				}
			}
		},
	},
	methods: {
		async OnSave(kapat) {
			debugger;

			this.submitted = true;
			this.v$.$touch();

			if (this.v$.$invalid) {
				this.v$.$errors.forEach(element => {
					this.$toast.add({severity:'error', summary: 'Eksik alanlar var.', detail: element.$message, life: 3500});
				});
			} else {
				this.$toast.add({severity:'success', summary: 'Bilgi', detail:'Başarıyla Kaydedildi', life: 3000});
				if (kapat) {
					setTimeout(() => {
						window.close();
					}, 1000);
				}
			}
		},
		async OnDelete(){
            this.deleteItem('product', this.entityId);
        },
		getCheckPrivilage(modulName){
			return this.checkPrivilage(this.profileData, modulName);
		},
		UrunAilesiSelect(event) {
			this.mainData["urunAilesiId"] = event.key;
			this.mainData["urunAilesiName"] = event.label;
			
            debugger;
		},
		onRowSelectFiyatListesiKalemi(event){
			this.displayFiyatListesiKalemi = true;
			console.log(event);
			this.dataFiyatListesiKalemi["name"] = event.name;
			this.dataFiyatListesiKalemi["entityId"] = event.entityId;
			this.dataFiyatListesiKalemi["amount"] = parseInt(event.amount.replace(/\./g, '').replace(',', '.'));
		},
		async OnSaveFiyatListesiKalemi(event){
			try {
				const response = await this.crmService.updateProductPriceLevel(this.dataFiyatListesiKalemi);
				if (response) {
					if (response.hata == true) {
						this.$toast.add({severity:'error', summary: 'Kaydedilemedi', detail: response.hataAciklamasi, life: 5000});
					}else {
						this.$toast.add({severity:'success', summary: 'Bilgi', detail:'Başarıyla Güncellendi', life: 3000});
						this.dataFiyatListesiKalemi = {};
						this.displayFiyatListesiKalemi = false;

						/*
						setTimeout(() => {
							
							this.OnLoad();
						}, 1000);
						*/
					}
				}
			} catch (error) {
				this.$toast.add({severity:'error', summary: 'Eksik alanlar var.', detail: error.message, life: 3500});
			}

			console.log(event);
		},
		onRowSelectOpsiyon(event) {
			let features = 'directories=no,menubar=no,status=no,titlebar=no,toolbar=no';

			let routeData = this.$router.resolve({ name: "product", params: { id: event.data.ID  } });
			window.open(routeData.href, '_blank', features);
        },
		formatNumber(value) {
			if (value) {
				return value.toLocaleString('tr-TR');
			}
            else {
				return value;
			}
        },
		handleScroll() {
			const el = document.getElementById('stickBar');

			if (window.scrollY > 50) {
				el.style.boxShadow = '0px 2px 1px rgba(0, 0, 0, 0.09), 0px 4px 2px rgba(0, 0, 0, 0.09), 0px 8px 4px rgba(0, 0, 0, 0.09), 0px 16px 8px rgba(0, 0, 0, 0.09), 0px 32px 16px rgba(0, 0, 0, 0.09)';
			}
			else {
				el.style.boxShadow = '';
			}
		},
	},
	watch: {
		profileData(val) {
			if (val) {
				if (val.moduller) {
					const filtered = val.moduller.filter(x => x.name == 'Ürünler');
					if (filtered.length == 0) {
						this.$router.replace({ name: 'accessdenied' });
					}
				}
			}
		}
	},
	validations() {
		return {
			mainData: {
				firmaAdi: {
					required : helpers.withMessage('Adı alanı dolu olmalıdır!', required),
				},
				telefon1: {
					required : helpers.withMessage('Soyadı alanı dolu olmalıdır!', required),
				},
				epostaAdresi1: {
					email : helpers.withMessage('Geçerli bir mail adresi giriniz!', email),
				},
			},
		}
	}
}
</script>

<style lang="scss" scoped>

</style>
